<template>
  <view-wrapper>
    <a-row :gutter="16">
      <a-col :span="8">
        <a-card :loading="loading">
          <template #title>
            <div class="flex justify-between text-[14px] text-gray-400">
              <span>采购金额</span>
              <span>{{ formatDate(data.date, 'YYYY年MM月') }}</span>
            </div>
          </template>
          <div class="flex flex-col items-center justify-center">
            <span class="text-[36px] text-primary">{{ formatMoney(data.purchaseAmount, '￥') }}</span>
            <div class="flex justify-center mt-[20px]">
              <span class="mr-[50px]">上月金额: {{ toFixed(data.purchaseAmountLastMonth) }}</span>
              <div v-if="data.purchaseAmountRise === 'Y'" class="text-[red]">
                <arrow-up-outlined />
                <span> {{ data.purchaseAmountRiseRate }} </span>
              </div>
              <div v-else>
                <arrow-down-outlined />
                <span> {{ data.purchaseAmountFallRate }} </span>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :loading="loading">
          <template #title>
            <div class="flex justify-between text-[14px] text-gray-400">
              <span>成交单数</span>
              <span>{{ formatDate(data.date, 'YYYY年MM月') }}</span>
            </div>
          </template>
          <div class="flex flex-col items-center justify-center">
            <div>
              <span class="text-[36px] text-primary">{{ data.purchaseCount }}</span>
              <span class="ml-[10px] tex-[24px]">单</span>
            </div>
            <div class="flex justify-center mt-[20px]">
              <span class="mr-[50px]">上月成交:{{ data.purchaseCountLastMonth }}</span>
              <div v-if="data.purchaseCountRise === 'Y'" class="text-[red]">
                <arrow-up-outlined />
                <span> {{ data.purchaseCountRiseRate }} </span>
              </div>
              <div v-else>
                <arrow-down-outlined />
                <span> {{ data.purchaseCountFallRate }} </span>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :loading="loading">
          <template #title>
            <div class="flex justify-between text-[14px] text-gray-400">
              <span>报价完成率</span>
              <span>{{ formatDate(data.date, 'YYYY年MM月') }}</span>
            </div>
          </template>
          <div class="flex flex-col items-center justify-center">
            <span class="text-[36px] text-primary">{{ data.quotationFinishRate }}</span>
            <div class="flex justify-center mt-[20px]">
              <span class="mr-[50px]">上月完成率:{{ data.quotationFinishRateLastMonth }}</span>
              <div v-if="data.quotationFinishIsRise === 'Y'" class="text-[red]">
                <arrow-up-outlined />
                <span> {{ data.quotationFinishRiseRate }} </span>
              </div>
              <div v-else>
                <arrow-down-outlined />
                <span> {{ data.quotationFinishRiseRate }} </span>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-row :gutter="16" class="mt-[20px]">
      <a-col :span="16">
        <e-table :columns="columns" :data-source="data.supplierSummaries" row-key="supplierId">
          <template #index="{ index }">
            <span>{{ index + 1 }}</span>
          </template>
          <template #count="{ text }">
            <span>{{ text }}</span>
          </template>
          <template #supplierName="{ text, record }">
            <a-tooltip :title="record.supplierName">
              <span>{{ text }}</span>
            </a-tooltip>
          </template>
          <template #amount="{ text }">
            <span>{{ formatMoney(text, '￥') }}</span>
          </template>
          <template #operation="{ record }">
            <span class="operation-model">
              <a class="text-primary" @click="tableOperationCheckClick(record)">查看</a>
            </span>
          </template></e-table
        >
      </a-col>
      <a-col :span="8">
        <a-card>
          <template #title>
            <div class="flex justify-between text-[14px]">
              <span style="font-size: 16px; color: rgba(0, 0, 0, 0.85)">我的关注</span>
              <span style="color: rgba(0, 0, 0, 0.45)">{{ data.date }}</span>
            </div>
          </template>

          <div class="flex justify-evenly items-center">
            <div>
              <div style="color: rgba(0, 0, 0, 0.45)">关注商家数量</div>
              <div style="font-size: 30px" class="text-center">{{ data.supplierCount }}</div>
            </div>
            <a-divider style="height: 40px" type="vertical" />
            <div>
              <div style="color: rgba(0, 0, 0, 0.45)">关注商家成交单数</div>
              <div style="font-size: 30px" class="text-center">
                {{ data.supplierPurchaseCount }}
              </div>
            </div>
            <a-divider style="height: 40px" type="vertical" />
            <div>
              <div style="color: rgba(0, 0, 0, 0.45)">关注商家成交金额（元）</div>
              <div style="font-size: 30px" class="text-center">
                {{ data.supplierPurchaseAmount }}
              </div>
            </div>
          </div>
        </a-card>
        <div style="margin-top: 24px"></div>
        <a-card :loading="loading" v-if="data.vehicleSummaries.length">
          <template #title>
            <div class="flex justify-between text-[14px] text-gray-400">
              <span>车品牌成交top5</span>
              <span>{{ formatDate(data.date, 'YYYY年MM月') }}</span>
            </div>
          </template>
          <e-echarts
            autoresize
            :option="{
              tooltip: { trigger: 'item' },
              legend: {
                type: 'scroll',
                orient: 'vertical',
                top: 'middle',
                right: 20,
                formatter: echartsLegendFormatter,
                textStyle: {
                  rich: {
                    x: { color: 'rgba(0, 0, 0, 0.45)' }
                  }
                }
              },
              dataset: { dimensions: ['vehicleBrand', 'purchaseCount'], source: data.vehicleSummaries },
              series: [
                {
                  name: '车品牌成交',
                  right: 200,
                  type: 'pie',
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                  label: { show: false, position: 'center' },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '40',
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: { show: false }
                }
              ]
            }"
          ></e-echarts>
        </a-card>
      </a-col>
    </a-row>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { Row as ARow, Col as ACol, Tooltip as ATooltip, Card as ACard, Divider as ADivider } from 'ant-design-vue'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons-vue'

import { useAjax, formatDate, formatMoney, toFixed } from '@vue-mfe/utils'
import { useRouter } from 'vue-router'

import { multiply } from 'lodash-es'

import type { BusinessSummaryDto } from '@vue-mfe/container/interface'

const router = useRouter()

const data = reactive<
  BusinessSummaryDto & {
    purchaseAmountRise: string
    purchaseAmountRiseRate: string
    purchaseAmountFallRate: string
    purchaseCountRise: string
    purchaseCountRiseRate: string
    purchaseCountFallRate: string
    quotationFinishRateLastMonth: string
    quotationFinishIsRise: string
    quotationFinishRate: string
    quotationFinishRiseRate: string
  }
>(
  {
    date: '',
    supplierCount: 0,
    supplierPurchaseCount: 0,
    supplierPurchaseAmount: 0,
    purchaseAmount: 0,
    purchaseAmountLastMonth: 0,
    purchaseCount: 0,
    purchaseCountLastMonth: 0,
    inquiryCount: 0,
    inquiryCountLastMonth: 0,
    inquiryQuoteCount: 0,
    inquiryQuoteCountLastMonth: 0,

    purchaseAmountRise: '',
    purchaseAmountRiseRate: '',
    purchaseAmountFallRate: '',
    purchaseCountRise: '',
    purchaseCountRiseRate: '',
    purchaseCountFallRate: '',
    quotationFinishRateLastMonth: '',
    quotationFinishIsRise: '',
    quotationFinishRate: '',
    quotationFinishRiseRate: '',

    supplierSummaries: [],
    vehicleSummaries: []
  }
)

const toPercent = (value: number, precision = 2) => {
  return `${multiply(value, 100).toFixed(precision)}%`
}

const { loading } = useAjax<BusinessSummaryDto>(
  {
    action: 'GET /client/summary',
    success (res) {
      Object.assign(data, res[0])

      const {
        inquiryCount,
        inquiryCountLastMonth,
        inquiryQuoteCount,
        inquiryQuoteCountLastMonth,
        purchaseAmount,
        purchaseAmountLastMonth,
        purchaseCount,
        purchaseCountLastMonth
      } = data

      if (purchaseAmountLastMonth === 0) {
        data.purchaseAmountRise = 'Y'
        data.purchaseAmountRiseRate = `比上月增长了￥${purchaseAmount - purchaseAmountLastMonth}`
      } else if (purchaseAmount - purchaseAmountLastMonth >= 0) {
        data.purchaseAmountRise = 'Y'
        data.purchaseAmountRiseRate = toPercent((purchaseAmount - purchaseAmountLastMonth) / purchaseAmountLastMonth)
      } else {
        data.purchaseAmountRise = 'N'
        data.purchaseAmountFallRate = toPercent(
          Math.abs(purchaseAmount - purchaseAmountLastMonth) / purchaseAmountLastMonth
        )
      }
      // 成交单数部分
      if (purchaseCountLastMonth === 0) {
        data.purchaseCountRise = 'Y'
        data.purchaseCountRiseRate = `比上月增长了${purchaseCount - purchaseCountLastMonth}单`
      } else if (purchaseCount - purchaseCountLastMonth >= 0) {
        data.purchaseCountRise = 'Y'
        data.purchaseCountRiseRate = toPercent((purchaseCount - purchaseCountLastMonth) / purchaseCountLastMonth)
      } else {
        data.purchaseCountRise = 'N'
        data.purchaseCountFallRate = toPercent(
          Math.abs(purchaseCount - purchaseCountLastMonth) / purchaseCountLastMonth
        )
      }
      // 报价完成率部分
      // 上月报价完成率
      if (inquiryQuoteCountLastMonth === 0) data.quotationFinishRateLastMonth = toPercent(0)
      else {
        data.quotationFinishRateLastMonth = toPercent(inquiryQuoteCountLastMonth / inquiryCountLastMonth)
      }

      if (inquiryQuoteCount - inquiryCountLastMonth >= 0) data.quotationFinishIsRise = 'Y'
      else data.quotationFinishIsRise = 'N'
      if (inquiryQuoteCount === 0) {
        // 当月报价完成率
        data.quotationFinishRate = toPercent(0)
        // 相比上月的完成率
        data.quotationFinishRiseRate = inquiryQuoteCountLastMonth ? toPercent(100) : toPercent(0)
      } else {
        data.quotationFinishRate = toPercent(inquiryQuoteCount / inquiryCount)
        data.quotationFinishRiseRate = toPercent(
          Math.abs(inquiryQuoteCount - inquiryQuoteCountLastMonth) / inquiryQuoteCountLastMonth
        )
      }
    }
  }
)

const columns = [
  { title: '序号', slots: { customRender: 'index' } },
  { title: '商家名称', dataIndex: 'supplierShortName', slots: { customRender: 'supplierName' } },
  { title: '成交量', dataIndex: 'purchaseCount', slots: { customRender: 'count' } },
  { title: '成交金额', dataIndex: 'purchaseAmount', slots: { customRender: 'amount' } },
  { title: '操作', slots: { customRender: 'operation' } }
]
const tableOperationCheckClick = (row: any) =>
  router.push({ path: '/client/purchase/order', query: { supplierId: row.supplierId } })

const echartsLegendFormatter = (name: string) => {
  const current = data.vehicleSummaries.find((item) => item.vehicleBrand === name)
  const sum = data.vehicleSummaries.reduce((sum, item) => (sum += item.purchaseCount), 0)
  return `${current!.vehicleBrand}  {x| | ${toPercent(current!.purchaseCount / sum)}} ${formatMoney(
    current!.purchaseAmount,
    '￥'
  )}`
}
</script>
